import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { Box, Button, Typography } from "@mui/material";

import logo from "assets/images/logo_horizontal.png";

import { api } from "../../lib/axios";

function FileDownloadPage() {
  const { id } = useParams();

  const [downloadMessage, setDownloadMessage] = useState("");

  const downloadFile = async () => {
    if (!id || id === "") {
      setDownloadMessage("Arquivo não informado");
      return;
    }
    try {
      const { data } = await api.get(`/file/${id}`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `receita.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      setDownloadMessage("Erro ao buscar dados do arquivo");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
        marginBottom: "80px",
      }}
    >
      <img src={logo} alt="Logo" style={{ height: "100px", width: "auto" }} />
      <Box sx={{ margin: 5 }}>
        <Button
          onClick={() => downloadFile()}
          variant="contained"
          color="success"
          sx={{
            fontSize: "1.2rem",
          }}
        >
          BAIXAR O ARQUIVO
        </Button>
      </Box>
      {downloadMessage !== "" && (
        <Typography variant="h5">{downloadMessage}</Typography>
      )}
    </Box>
  );
}

export default FileDownloadPage;
