/* eslint-disable no-plusplus */
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import routes from "routes";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";

import CopyToClipboard from "react-copy-to-clipboard";
import Card from "react-credit-cards-2";
// import "react-credit-cards-2/es/styles-compiled.css";
import {
  Card as CardMUI,
  AppBar,
  Tabs,
  Tab,
  Grid,
  Container,
  Divider,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
  Backdrop,
  Box,
} from "@mui/material";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Menu from "components/Menu";
import LogoMPO from "assets/images/logo_horizontal.png";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";
import { api } from "../../lib/axios";

function StorePayment() {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const history = useHistory();
  const { id } = useParams();
  const authenticatedUser = JSON.parse(localStorage.getItem("user")) ?? false;
  const routeIndex = authenticatedUser.type ?? "public";
  if (!authenticatedUser) {
    window.location.replace("/login");
  }

  const [loading, setLoading] = useState(false);
  const [alertComponent, setAlertComponent] = useState({
    message: "",
    type: "info",
  });

  const [product, setProduct] = useState();
  const [purchase, setPurchase] = useState();

  const [activeTab, setActiveTab] = useState(0);

  const [tabType, setTabType] = useState("ESCOLHA");

  const [name, setName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [installments, setInstallments] = useState([]);
  const [installment, setInstallment] = useState(1);
  const [isFrontOfCardVisible, setIsFrontOfCardVisible] = useState(true);
  const [pixNumber, setPixNumber] = useState("");
  const [pixQR, setPixQR] = useState("");
  const [copied, setCopied] = useState(false);

  const [zip, setZip] = useState("");
  const [address, setAddress] = useState("");
  const [number, setNumber] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");

  const handleExpiry = (input) => {
    const caracteres = input.replace("/", "").split("");
    let newDate = "";
    if (caracteres.length >= 2) {
      for (let i = 0; i < caracteres.length; i++) {
        if (i === 2) {
          newDate = `${newDate}/`;
        }
        newDate = `${newDate}${caracteres[i]}`;
      }
      setExpiry(newDate);
    } else {
      setExpiry(input);
    }
  };

  const clearAddress = () => {
    setAddress("");
    setNumber("");
    setNeighborhood("");
    setCity("");
    setState("");
  };
  const clearPayment = () => {
    setName("");
    setCardNumber("");
    setExpiry("");
    setCvc("");
    setIsFrontOfCardVisible(true);
    setPixNumber("");
    setPixQR("");
    setCopied(false);
  };

  const getPayment = async () => {
    try {
      const { data } = await api.get(`/payments/sales_products/${id}`);
      setPurchase(data);

      const productOrdered = data.date.items[0];
      setProduct(productOrdered);
      setPixNumber(data.qrCodeTxt.qr_code);
      setPixQR(data.qrCodeImage.qr_code_url);
      setInstallments(data.installments);
    } catch (err) {
      setAlertComponent({
        message:
          err?.response?.data?.message ||
          "Erro na busca dos dados de pagamento",
        type: "error",
      });
    }
  };

  function toggleCardFlip(e) {
    e.preventDefault();
    setIsFrontOfCardVisible(!isFrontOfCardVisible);
  }

  // eslint-disable-next-line no-unused-vars
  const handleSubmit = async (e) => {
    if (
      zip === "" ||
      city === "" ||
      state === "" ||
      address === "" ||
      number === ""
    ) {
      setAlertComponent({
        message: "Erro nas informações de endereço",
        type: "error",
      });
      return;
    }
    setLoading(true);
    e.preventDefault();
    try {
      const expireMonth = expiry.split("/")[0];
      const expireYear = `20${expiry.split("/")[1]}`;
      const objPayment = {
        nameHolder: name,
        number: cardNumber,
        cvv: cvc,
        expirationMonth: expireMonth,
        expirationYear: expireYear,
        billing_address: {
          country: "BR",
          state,
          city,
          zip_code: zip,
          address: `${address}, ${number}, ${neighborhood}`,
        },
        installments: installment,
      };

      const response = await api.post(
        `/payments/sales_products/${purchase.id}/pay`,
        objPayment,
      );
      if (response.data) {
        history.push(`/productconfirmation/${purchase.id}`);
      }
      clearPayment();
    } catch (err) {
      setAlertComponent({
        message: err?.response?.data?.message || "Erro no pagamento da compra",
        type: "error",
      });
    }
    // setLoading(false);
  };

  const handleTabType = ({ currentTarget }, newValue) => {
    setActiveTab(newValue);
    setTabType(currentTarget.id);
  };

  const findZIP = (zipCode) => {
    setLoading(true);
    fetch(`https://viacep.com.br/ws/${zipCode}/json/`, { mode: "cors" })
      .then((res) => res.json())
      .then((data) => {
        if (data.erro) {
          // eslint-disable-next-line no-alert
          alert("CEP não existente");
        } else {
          setAddress(data.logradouro);
          setNeighborhood(data.bairro);
          setCity(data.localidade);
          setState(data.uf);
        }
      });
    setLoading(false);
  };

  useEffect(() => {
    async function getInfos() {
      await getPayment();
    }
    getInfos();
  }, []);

  const onCopy = useCallback(() => {
    setCopied(true);
  }, []);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponent]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
            <Typography variant="body1" style={{ color: "white", padding: 20 }}>
              Carregando...
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <Container>
        <Grid container alignItems="top" py={20}>
          <Grid item xs={12} lg={12} pr={{ xl: 1, lg: 0 }}>
            <CardMUI>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                py={1}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="body2" color="white" mt={0}>
                  Pagamento
                </MKTypography>
              </MKBox>
              {alertComponent.message !== "" && (
                <Grid item xs={12} style={{ margin: 20 }}>
                  <MKAlert color={alertComponent.type}>
                    {alertComponent.message}
                  </MKAlert>
                </Grid>
              )}
              <Grid container p={2} style={{ minHeight: "70vh" }}>
                {purchase && product && (
                  <>
                    <Grid
                      item
                      xs={12}
                      lg={7}
                      py={1}
                      textAlign="center"
                      bgcolor="#f0f0f0"
                      borderRadius={2}
                    >
                      <MKBox mx={{ xs: 0, md: 2 }} mb={1} height="auto">
                        <MKTypography variant="body1" py={2}>
                          Forma de Pagamento
                        </MKTypography>
                        <Grid container>
                          <Grid
                            item
                            xs={10}
                            md={8}
                            lg={6}
                            sx={{ mx: "auto", textAlign: "center" }}
                          >
                            <AppBar position="static">
                              <Tabs value={activeTab} onChange={handleTabType}>
                                <Tab
                                  id="ESCOLHA"
                                  label={
                                    <MKBox py={0.5} px={2} color="inherit">
                                      ESCOLHA
                                    </MKBox>
                                  }
                                />
                                <Tab
                                  id="pix"
                                  label={
                                    <MKBox py={0.5} px={2} color="inherit">
                                      PIX
                                    </MKBox>
                                  }
                                />
                                <Tab
                                  id="card"
                                  label={
                                    <MKBox py={0.5} px={2} color="inherit">
                                      Cartão
                                    </MKBox>
                                  }
                                />
                              </Tabs>
                            </AppBar>
                          </Grid>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              lg={12}
                              sx={{ mx: "auto", textAlign: "center" }}
                            >
                              {tabType === "ESCOLHA" && (
                                <MKBox>
                                  <MKTypography variant="body2">
                                    Escolha uma forma de pagamento.
                                  </MKTypography>
                                </MKBox>
                              )}
                              {tabType === "pix" && pixNumber && (
                                <Grid>
                                  <CopyToClipboard
                                    onCopy={onCopy}
                                    text={pixNumber}
                                  >
                                    <MKBox
                                      component="img"
                                      src={pixQR}
                                      alt="QR Code PIX"
                                      width="60%"
                                      p={1}
                                      sx={{ cursor: "pointer" }}
                                    />
                                  </CopyToClipboard>
                                  <CopyToClipboard
                                    onCopy={onCopy}
                                    text={pixNumber}
                                  >
                                    <Grid lineHeight={0.5} px={2}>
                                      <MKTypography variant="caption">
                                        {pixNumber}
                                      </MKTypography>
                                    </Grid>
                                  </CopyToClipboard>
                                  {copied ? (
                                    <>
                                      <MKTypography
                                        variant="h4"
                                        sx={{ color: "#FF0000" }}
                                      >
                                        CÓDIGO COPIADO
                                      </MKTypography>
                                      <MKTypography variant="body2">
                                        para sua área de transferência
                                      </MKTypography>
                                    </>
                                  ) : (
                                    <MKTypography variant="body2" my={2}>
                                      Copie a chave acima ou clique no QR Code!
                                    </MKTypography>
                                  )}
                                  <MKTypography variant="body2" my={2}>
                                    VOCÊ RECEBERÁ A CONFIRMAÇÃO DO PAGAMENTO POR
                                    E-MAIL E SMS
                                  </MKTypography>
                                </Grid>
                              )}
                              {tabType === "card" && (
                                <Grid container pt={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    sx={{ mx: "auto", textAlign: "center" }}
                                    onClick={(e) => toggleCardFlip(e)}
                                  >
                                    <Card
                                      cvc={cvc || ""}
                                      expiry={expiry || ""}
                                      name={name || ""}
                                      number={cardNumber || ""}
                                      focused={
                                        isFrontOfCardVisible ? "number" : "cvc"
                                      }
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    lg={12}
                                    sx={{ mx: "auto", textAlign: "center" }}
                                  >
                                    {/* <MKTypography
                                    variant="body2"
                                    textAlign="center"
                                    sx={{ pt: 3 }}
                                  > */}
                                    <Grid
                                      container
                                      spacing={0.5}
                                      paddingX={2}
                                      paddingTop={3}
                                    >
                                      {installments &&
                                        installments.length > 0 && (
                                          <Grid
                                            item
                                            xs={12}
                                            md={12}
                                            style={{
                                              backgroundColor: "#E0E0E0",
                                              borderRadius: 10,
                                              padding: 20,
                                            }}
                                          >
                                            <Typography variant="body2">
                                              Parcelamento Opcional
                                            </Typography>
                                            <Select
                                              variant="outlined"
                                              id="installment"
                                              value={installment}
                                              sx={{ height: "45px" }}
                                              onChange={(e) =>
                                                setInstallment(e.target.value)
                                              }
                                              style={{ width: "100%" }}
                                              required
                                            >
                                              {installments.map(
                                                (installmentDB) => (
                                                  <MenuItem
                                                    key={installmentDB.number}
                                                    value={installmentDB.number}
                                                  >
                                                    {installmentDB.number} x R${" "}
                                                    {installmentDB.quota
                                                      .toFixed(2)
                                                      .replace(".", ",")}
                                                  </MenuItem>
                                                ),
                                              )}
                                            </Select>
                                          </Grid>
                                        )}
                                      <Grid item xs={12} md={12}>
                                        <MKInput
                                          name="name"
                                          label="Nome"
                                          value={name}
                                          onChange={(e) =>
                                            setName(e.target.value)
                                          }
                                          onFocus={() =>
                                            setIsFrontOfCardVisible(true)
                                          }
                                          fullWidth
                                          required
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={12}>
                                        <MKInput
                                          name="cardNumber"
                                          label="Número"
                                          value={cardNumber}
                                          onChange={(e) =>
                                            setCardNumber(
                                              e.target.value.substring(0, 16),
                                            )
                                          }
                                          onFocus={() =>
                                            setIsFrontOfCardVisible(true)
                                          }
                                          fullWidth
                                          required
                                        />
                                      </Grid>
                                      <Grid item xs={8} md={8}>
                                        <MKInput
                                          name="expiry"
                                          label="Validade"
                                          value={expiry}
                                          placeholder="MM/YY"
                                          onChange={(e) =>
                                            handleExpiry(
                                              e.target.value.substring(0, 5),
                                            )
                                          }
                                          onFocus={() =>
                                            setIsFrontOfCardVisible(true)
                                          }
                                          fullWidth
                                          required
                                        />
                                      </Grid>
                                      <Grid item xs={4} md={4}>
                                        <MKInput
                                          name="cvc"
                                          label="CVC"
                                          value={cvc}
                                          placeholder="CVC"
                                          onChange={(e) =>
                                            setCvc(
                                              e.target.value.substring(0, 4),
                                            )
                                          }
                                          onFocus={() =>
                                            setIsFrontOfCardVisible(false)
                                          }
                                          fullWidth
                                          required
                                        />
                                      </Grid>
                                      <Divider />
                                      <Grid item xs={8} md={8}>
                                        <MKInput
                                          variant="outlined"
                                          name="zip"
                                          label="CEP"
                                          value={zip}
                                          onChange={(e) => {
                                            setZip(e.target.value);
                                            clearAddress();
                                          }}
                                          onBlur={() => findZIP(zip)}
                                          fullWidth
                                        />
                                      </Grid>
                                      <Grid item xs={4} md={4}>
                                        <MKInput
                                          variant="outlined"
                                          name="number"
                                          label="Número"
                                          value={number}
                                          onChange={(e) =>
                                            setNumber(e.target.value)
                                          }
                                          fullWidth
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={12}>
                                        <MKInput
                                          variant="outlined"
                                          name="address"
                                          label="Logradouro"
                                          value={address}
                                          onChange={(e) =>
                                            setAddress(e.target.value)
                                          }
                                          fullWidth
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={12}>
                                        <MKInput
                                          variant="outlined"
                                          name="neighborhood"
                                          label="Bairro"
                                          value={neighborhood}
                                          onChange={(e) =>
                                            setNeighborhood(e.target.value)
                                          }
                                          fullWidth
                                        />
                                      </Grid>
                                      <Grid item xs={8} md={8}>
                                        <MKInput
                                          variant="outlined"
                                          name="city"
                                          label="Cidade"
                                          value={city}
                                          fullWidth
                                        />
                                      </Grid>
                                      <Grid item xs={4} md={4}>
                                        <MKInput
                                          variant="outlined"
                                          name="state"
                                          label="Estado"
                                          value={state}
                                          fullWidth
                                        />
                                      </Grid>
                                      <Divider />
                                      <Grid item xs={12} md={12}>
                                        <MKButton
                                          type="submit"
                                          variant="gradient"
                                          color="info"
                                          onClick={(e) => handleSubmit(e)}
                                          disabled={!!loading}
                                          style={{ width: "100%" }}
                                        >
                                          {loading ? "PROCESSANDO..." : "PAGAR"}
                                        </MKButton>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </MKBox>
                    </Grid>
                    <Grid item xs={12} lg={5} py={2}>
                      <MKBox mx={{ lg: 2, mx: 0 }} mb={1} height="auto">
                        <MKTypography variant="body1" py={2}>
                          <b>Dados da Compra:</b>
                        </MKTypography>
                        <MKBox width="100%" py={1}>
                          <MKTypography variant="body2">
                            <b>Produto:</b> {product.description}
                          </MKTypography>
                          <MKTypography variant="body2">
                            <b>Valor:</b>{" "}
                            {(Number(product.amount) / 100).toLocaleString(
                              "pt-BR",
                              {
                                style: "currency",
                                currency: "BRL",
                              },
                            )}
                          </MKTypography>
                          <MKTypography
                            variant="body2"
                            py={2}
                            sx={{ textAlign: "justify" }}
                          >
                            Agora só falta você efetuar o pagamento para
                            liberação da compra. Você precisa fazer o pagamento
                            em até 60min ou a compra será cancelada pelo
                            sistema.
                          </MKTypography>
                          <MKTypography
                            variant="body2"
                            py={2}
                            sx={{ textAlign: "justify" }}
                          >
                            Se precisar de ajuda é só nos contactar!
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                    </Grid>
                  </>
                )}
              </Grid>
            </CardMUI>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default StorePayment;
