/* eslint-disable no-plusplus */
/* eslint-disable no-alert */
import React, { useEffect, useState } from "react";
import routes from "routes";

import LogoMPO from "assets/images/logo_horizontal.png";

import {
  Card,
  Container,
  CircularProgress,
  Grid,
  Backdrop,
  Box,
  Typography,
} from "@mui/material";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Menu from "components/Menu";
import MKInput from "components/MKInput";
import MKAlert from "components/MKAlert";
import MKButton from "components/MKButton";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/pt-br";

import { api } from "../../lib/axios";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("pt-br");

function UserArea() {
  const [authenticatedUser, setAuthenticatedUser] = useState(null);
  const [routeIndex, setRouteIndex] = useState("public");

  const [alertComponent, setAlertComponent] = useState({
    message: null,
    type: null,
  });
  const [loading, setLoading] = useState(false);

  const [validCPF, setValidCPF] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [cpf, setCpf] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [zip, setZip] = useState("");
  const [address, setAddress] = useState("");
  const [number, setNumber] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [complement, setComplement] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");

  const handleCPF = (cpfInput) => {
    const cpfFormated = cpfInput
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1");
    setCpf(cpfFormated);
  };

  const isValidCPF = (numbersCPF) => {
    if (numbersCPF.length !== 11) {
      return false;
    }
    if (/^(\d)\1{10}$/.test(numbersCPF)) {
      return false;
    }

    let sum = 0;
    let remainder;
    for (let i = 1; i <= 9; i++) {
      sum += parseInt(numbersCPF[i - 1], 10) * (11 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }
    if (remainder !== parseInt(numbersCPF[9], 10)) {
      return false;
    }
    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum += parseInt(numbersCPF[i - 1], 10) * (12 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }
    if (remainder !== parseInt(numbersCPF[10], 10)) {
      return false;
    }
    return true;
  };

  const handleCellPhone = (cellPhoneInput) => {
    const cellPhoneFormated = cellPhoneInput
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d)/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
    setCellphone(cellPhoneFormated);
  };

  const getUserDate = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/users/${authenticatedUser.id}`);

      if (response.data) {
        const user = response.data;
        setName(user.name ?? "");
        setEmail(user.email ?? "");
        if (user.cellphone) {
          handleCellPhone(user.cellphone.substr(2));
        }
        if (user.cpf) {
          handleCPF(user.cpf);
          setValidCPF(isValidCPF(user.cpf));
        }
        if (user.birth_date) {
          const dataConvertida = dayjs(user.birth_date)
            .tz("UTC")
            .format("YYYY-MM-DD");
          setBirthDate(dataConvertida);
        }
        setZip(user.zip ?? "");
        setAddress(user.address ?? "");
        setNumber(user.number ?? "");
        setComplement(user.complement ?? "");
        setNeighborhood(user.neighborhood ?? "");
        setCity(user.city ?? "");
        setState(user.state ?? "");
      }
    } catch (err) {
      setAlertComponent({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    }
    setLoading(false);
  };

  const findZIP = (zipCode) => {
    setLoading(true);
    try {
      fetch(`https://viacep.com.br/ws/${zipCode}/json/`, { mode: "cors" })
        .then((res) => res.json())
        .then((data) => {
          if (data.erro) {
            alert("CEP não existente");
          } else {
            setAddress(data.logradouro);
            setNeighborhood(data.bairro);
            setCity(data.localidade);
            setState(data.uf);
          }
        });
    } catch (err) {
      setAlertComponent({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    }
    setLoading(false);
  };

  const clearAddress = () => {
    setAddress("");
    setNumber("");
    setNeighborhood("");
    setComplement("");
    setCity("");
    setState("");
  };

  const updateUser = async () => {
    if (dayjs(birthDate).format("YYYY-MM-DD") === "1924-10-08") {
      setAlertComponent({
        message: "Corrija a data de nascimento",
        type: "error",
      });
      return;
    }
    if (zip !== "") {
      if (city === "" || state === "" || address === "" || number === "") {
        setAlertComponent({
          message: "Erro nas informações de endereço",
          type: "error",
        });
        return;
      }
    }
    setLoading(true);
    try {
      if (password && password !== password2) {
        setAlertComponent({
          message: "Senhas são diferentes.",
          type: "error",
        });
        setLoading(false);
        return;
      }

      const userCell = `55${cellphone.replace(/\D/g, "")}`;
      const uCPF = cpf.replace(/\D/g, "");
      if (!isValidCPF(uCPF)) {
        setAlertComponent({
          message: "CPF inválido! Corrija-o antes de prosseguir.",
          type: "error",
        });
        setLoading(false);
        return;
      }

      if (
        uCPF.length !== 11 ||
        userCell.length !== 13 ||
        name === "" ||
        email === "" ||
        birthDate === "" ||
        birthDate.length !== 10
      ) {
        setAlertComponent({
          message: "Verifique os dados informados",
          type: "error",
        });
        setLoading(false);
        return;
      }

      const objectUpdate = {
        name,
        email,
        cellphone: userCell,
        cpf: uCPF,
        birth_date: dayjs(birthDate).format("YYYY-MM-DD"),
        address,
        zip,
        number,
        neighborhood,
        complement,
        city,
        state,
      };
      if (password) {
        objectUpdate.password = password;
      }
      const { data } = await api.put(
        `/users/${authenticatedUser.id}`,
        objectUpdate,
      );
      localStorage.setItem(
        "user",
        JSON.stringify({
          cpf: data.cpf,
          email: data.email,
          id: data.id,
          name: data.name,
          phone: data.cellphone,
          professional: authenticatedUser.professional,
          type: authenticatedUser.type,
        }),
      );

      setPassword("");
      setPassword2("");
      setAlertComponent({
        message: "Dados Atualizados com sucesso!",
        type: "success",
      });
    } catch (err) {
      setAlertComponent({
        message: err.response?.data?.message || "Ocorreu um erro!",
        type: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (authenticatedUser === null && !localStorage.getItem("user")) {
      window.location.replace("/login");
    }
    if (authenticatedUser === null && localStorage.getItem("user")) {
      setAuthenticatedUser(JSON.parse(localStorage.getItem("user")));
    }
  }, []);

  useEffect(() => {
    async function getAuthenticatedInfos() {
      if (authenticatedUser) {
        setRouteIndex(authenticatedUser.type);
        if (!name) {
          await getUserDate();
        }
      }
    }
    getAuthenticatedInfos();
  }, [authenticatedUser]);

  useEffect(() => {
    if (alertComponent.message !== "") {
      setTimeout(() => {
        setAlertComponent({
          message: "",
          type: "",
        });
      }, 10000);
    }
  }, [alertComponent]);

  return (
    <>
      <Backdrop
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(10, 10, 10, 0.5)",
          zIndex: 20000,
        }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" size={60} />

          <Box sx={{ maxHeight: "100px", overflow: "auto" }}>
            <Typography variant="body1" style={{ color: "white", padding: 20 }}>
              Carregando...
            </Typography>
          </Box>
        </Box>
      </Backdrop>
      <Menu brand={LogoMPO} routes={routes[routeIndex]} sticky />
      <Container>
        <MKBox
          component="section"
          position="relative"
          py={{ xs: 18, lg: 20 }}
          width="100%"
          height="100vh"
        >
          <Card>
            <MKBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-3}
              p={1}
              mb={1}
              textAlign="center"
              xs={12}
              lg={6}
            >
              <MKTypography
                variant="body1"
                fontWeight="medium"
                color="white"
                mt={1}
              >
                Dados do Usuário
              </MKTypography>
            </MKBox>
            {birthDate !== "" &&
              dayjs(birthDate).format("YYYY-MM-DD") === "1924-10-08" && (
                <MKAlert color="error" style={{ margin: 10 }}>
                  CORRIJA SUA DATA DE NASCIMENTO
                </MKAlert>
              )}
            <Grid container alignItems="top" width="100%">
              <Grid item xs={12} lg={6} px={2} mb={1}>
                <MKTypography variant="body2" fontWeight="medium" py={2}>
                  Dados Pessoais:
                </MKTypography>
                <MKBox width="100%" py={1}>
                  <MKInput
                    variant="outlined"
                    label="Nome"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    required
                  />
                </MKBox>
                <MKBox width="100%" py={1}>
                  <MKInput
                    variant="outlined"
                    label="E-mail"
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    required
                  />
                </MKBox>
                <MKBox width="100%" py={1}>
                  <MKInput
                    variant="outlined"
                    label="Telefone"
                    name="cellphone"
                    placeholder="(99) 99999-9999"
                    value={cellphone}
                    onChange={(e) => handleCellPhone(e.target.value)}
                    fullWidth
                    required
                  />
                </MKBox>
                <MKBox width="100%" py={1}>
                  <MKInput
                    variant="outlined"
                    name="cpf"
                    label="CPF"
                    placeholder="999.999.999-99"
                    value={cpf}
                    onChange={(e) => handleCPF(e.target.value)}
                    fullWidth
                    disabled={!!validCPF}
                    required
                  />
                </MKBox>
                <MKBox width="100%" py={1}>
                  <MKInput
                    variant="outlined"
                    name="birthDate"
                    label="Data de Nascimento"
                    type="date"
                    value={birthDate}
                    onChange={(e) => setBirthDate(e.target.value)}
                    fullWidth
                    required
                  />
                </MKBox>
                <MKBox width="100%" py={1}>
                  <MKInput
                    variant="outlined"
                    label="Nova Senha"
                    name="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                  />
                </MKBox>
                <MKBox width="100%" py={1}>
                  <MKInput
                    variant="outlined"
                    label="Confirme a Nova Senha"
                    name="password2"
                    type="password"
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                    fullWidth
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={6} px={2} mb={1}>
                <MKTypography variant="body2" fontWeight="medium" py={2}>
                  Endereço:
                </MKTypography>
                <MKBox width="100%" py={1}>
                  <MKInput
                    variant="outlined"
                    name="zip"
                    label="CEP"
                    value={zip}
                    onChange={(e) => {
                      setZip(e.target.value);
                      clearAddress();
                    }}
                    onBlur={() => findZIP(zip)}
                    fullWidth
                  />
                </MKBox>
                <MKBox width="100%" py={1}>
                  <MKInput
                    variant="outlined"
                    name="address"
                    label="Logradouro"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    fullWidth
                  />
                </MKBox>
                <MKBox width="100%" py={1}>
                  <MKInput
                    variant="outlined"
                    name="number"
                    label="Número"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    fullWidth
                  />
                </MKBox>
                <MKBox width="100%" py={1}>
                  <MKInput
                    variant="outlined"
                    name="neighborhood"
                    label="Bairro"
                    value={neighborhood}
                    onChange={(e) => setNeighborhood(e.target.value)}
                    fullWidth
                  />
                </MKBox>
                <MKBox width="100%" py={1}>
                  <MKInput
                    variant="outlined"
                    name="complement"
                    label="Complemento"
                    value={complement}
                    onChange={(e) => setComplement(e.target.value)}
                    fullWidth
                  />
                </MKBox>
                <MKBox width="100%" py={1}>
                  <MKInput
                    variant="outlined"
                    name="city"
                    label="Cidade"
                    value={city}
                    fullWidth
                  />
                </MKBox>
                <MKBox width="100%" py={1}>
                  <MKInput
                    variant="outlined"
                    name="state"
                    label="Estado"
                    value={state}
                    fullWidth
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} lg={12} mx={2} mb={1}>
                {alertComponent.message && (
                  <Grid item xs={12}>
                    <MKAlert color={alertComponent.type}>
                      {alertComponent.message}
                    </MKAlert>
                  </Grid>
                )}
                <MKBox pt={1}>
                  <MKButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={updateUser}
                  >
                    Atualizar Dados
                  </MKButton>
                </MKBox>
              </Grid>
            </Grid>
          </Card>
        </MKBox>
      </Container>
    </>
  );
}

export default UserArea;
